import posed from "react-pose";
import styled from "styled-components";

import { slideIn } from "utils/common";

const form = posed.form({ enter: { staggerChildren: 50 } });
const input = posed.input(slideIn("x"));
const textarea = posed.textarea(slideIn("x"));
const button = posed.div(slideIn("y"));

export const Form = styled(form)`
  width: 500px;
  max-width: 100%;
  text-align: right;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const TextInput = styled(input)`
  margin-bottom: 5px;
`;
export const TextArea = styled(textarea)`
  resize: none;
  min-height: 150px;
  margin-bottom: 5px;
`;

export const ButtonWrapper = styled(button)`
  margin-top: 10px;
`;
