import styled, { css } from "styled-components";
import { switchProp } from "styled-tools";

import variables from "styles/variables";

export const StyledButton = styled.button`
  line-height: 1;
  font-family: ${variables.fontFamily};
  padding: 10px 20px;
  border: 1px solid currentColor;

  cursor: pointer;
  text-transform: uppercase;
  color: ${variables.colors.light};
  background-color: transparent;

  transition: background-color 0.3s, border-color 0.3s;

  ${switchProp("color", {
    primary: css`
      border-color: ${variables.colors.primary};
      &:hover {
        background-color: ${variables.colors.primary};
      }
    `,
  })}
`;
