import React from "react";

import Button from "components/atoms/Button";

import { Form, FormGroup, TextInput, TextArea, ButtonWrapper } from "./styles";

const ContactView = () => (
  <Form action={process.env.GATSBY_FORM_SUBMIT_URL} method="POST">
    <FormGroup>
      <TextInput name="name" type="text" placeholder="Name" aria-label="Name" required />
      <TextInput name="email" type="email" placeholder="Enter email" aria-label="Email" required />
      <TextArea name="message" placeholder="Your message" aria-label="Message" required />
    </FormGroup>

    <ButtonWrapper>
      <Button color="primary" message="submit" />
    </ButtonWrapper>
  </Form>
);

export default ContactView;
