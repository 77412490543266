import React from "react";
import PropTypes from "prop-types";

import SSRAnimation from "components/atoms/SSRAnimation";
import variables from "styles/variables";

import { StyledButton } from "./styles";

const Button = ({ color, message, ...props }) => (
  <StyledButton color={color} {...props}>
    <SSRAnimation>{message}</SSRAnimation>
  </StyledButton>
);

Button.propTypes = {
  color: PropTypes.oneOf(variables.colors.schema),
  message: PropTypes.node.isRequired,
};

export default Button;
