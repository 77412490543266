import React from "react";

import SEO from "components/utils/SEO";
import Container from "components/atoms/Container";
import ContactView from "components/views/Contact";

const ContactPage = () => (
  <Container top="sm" right="lg" left="lg">
    <SEO
      title="Contact"
      keywords={["Hire", "Contract", "Programming"]}
      description="Contact me regarding web development - JavasSript(angular/react), Ruby on Rails"
    />

    <ContactView />
  </Container>
);

export default ContactPage;
